import './tietoaOpiskellusta.css'
import Puu from '../promoVideos/Soittimet_puu.png'
import React from 'react'
import Harrastus from '../images/Musiikkiharrastus.jpg'
//import Perusopetus from '../images/Opinnot.jpg'
import OpinnotKuva from '../images/opintoUusin.png'
//import Opintopolku from '../images/OpinnotUpdate.jpg'
import Helmet  from 'react-helmet'

const opiskelu = () => {


    const scrollLink = (name) => {
        // Hae elementti, johon halutaan skrollata
        const element = document.getElementById(name);
      
        // Jos elementti löytyy, skrollaa siihen sujuvasti
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      
        // Sulje dropdown-menu
        document.getElementById("opintoplut-content2").classList.remove("show2");
    };

    const myFunction = () => {
        document.getElementById("opintoplut-content2").classList.toggle("show2")

        window.onclick = function(event) {
            if (!event.target.matches('dropbtn2')) {
            
              var dropdowns = document.getElementsByClassName("dropdown-content2");
              console.log(dropdowns)
              var i;
              for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show2')) {
                  openDropdown.classList.remove('show2');
                }
              }
            }
          }
    }

    return(
        <div>
            <Helmet>
                <title>KUM Tietoa Opiskelusta </title>
                <meta name="description" content="Keskisen Uudenmaan Musiikkiopistossa musiikkia opiskellaan monipuolisesti ammattitaitoisten opettajiemme ohjauksessa.
                 Tavoitteenamme on tarjota edellytykset hyvän musiikkisuhteen syntymiselle siten, että oppilas voi iloita oppimisesta ja kehittää musiikillista osaamista.
                 Opetus antaa valmiuksia musiikin itsenäiseen harrastamiseen ja oppilaan oman kiinnostuksen mukaan pohjan jopa ammattiopintoihin.">
                </meta>
                <meta name="keywords" content="KUM, Keskisen Uudenmaan Musiikkiopisto, Opiskelu"></meta>
            </Helmet>
            <div className='anchor-links container'>
                {/*<button onClick={() => scrollLink("first")}>Tervetuloa musiikkiopistoon</button>*/}
                <button onClick={() => scrollLink("second")}>Opetettavat instrumentit</button>
                <button onClick={() => scrollLink("three")}>Ryhmäopetus</button>
                
                <div class="dropdown2">
                    <button onClick={myFunction} id="opinnot-button" className="dropbtn2">Opintopolut <span><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>

                    <div id="opintoplut-content2" className="dropdown2-content2">
                        <button onClick={() => scrollLink("taiteen-perus")}>Taiteen perusopetus</button>
                        <button onClick={() => scrollLink("avoinopetus")} >Avoin musiikkiopisto</button>
                        <button onClick={() => scrollLink("musikantit")} >Koulumusikantit ja musikantit</button>
                        <button onClick={() => scrollLink("soitinkokeilu")}>Soitinkokeilu</button>
                        <button onClick={() => scrollLink("ilmoittautuminen")} >Ilmoittautuminen musiikkiopistoon</button>                   
                    </div>
                </div>    

            </div>
            <br></br>
            <br></br>
            <div className='container'>
                <div id="first" className='opiskelu-1'>
                  
                    <div className='opiskelu-1-text'>
                        <h2>Tervetuloa musiikkiopistoon!</h2>
                        <p>
                        Keskisen Uudenmaan Musiikkiopistossa musiikkia opiskellaan monipuolisesti ammattitaitoisten opettajiemme ohjauksessa. Tavoitteenamme on tarjota edellytykset hyvän musiikkisuhteen syntymiselle siten, että oppilas voi iloita oppimisesta ja kehittää musiikillista osaamista. Opetus antaa valmiuksia musiikin itsenäiseen harrastamiseen ja oppilaan oman kiinnostuksen mukaan pohjan jopa ammattiopintoihin.                        
                        </p>

                        <p><strong>Meille on tärkeää:</strong></p>
                        <ul className='opiskelu-list'>
                            <li>oppijalähtöisyys ja onnistumisen ilo</li>
                            <li>yhdenvertaisuus ja tasa-arvo</li>
                            <li>yhteisöllisyys ja yhdessä tekeminen</li>
                            <li>laatu</li>
                            <li>ilo ja rakkaus musiikkiin</li>
                        </ul>
                        <p>Musiikkia voi harrastaa joko yksilö- tai ryhmäopetuksessa.</p>
                    </div>
                </div>
                <br></br>
                <div id="yksilöopiskelu" className='opiskelu-1'>
                    
                    <div className='opiskelu-1-text'>
                        <h2>Yksilöopetusta</h2>
                        <p>
                            Tarjoamme eri instrumenteissa joko taiteen perusopetuksen tai avoimen musiikkiopiston puolella. Taiteen perusopetus on tavoitteellista musiikin opiskelua, jossa opintoihin kuuluu soitto- tai laulutuntien lisäksi myös yhteismusisointia ja musiikin hahmotusaineiden opintoja. 
                        </p>
                        <p>
                            Avoimessa musiikkiopistossa kaikenikäiset oppilaat, myös aikuiset, voivat keskittyä oman instrumentin opiskeluun tai vaikkapa bändisoittoon.
                        </p>
                    </div>

                </div>

                <br></br>
                <div className='opiskelu-1'>

                    <div className='opiskelu-1-text'>
                        <p id="four"  className=''><strong>Taiteen perusopetus</strong></p>

                        <p>
                            Keskisen Uudenmaan Musiikkiopisto tarjoaa laajan oppimäärän opetussuunnitelman mukaista musiikin perusopetusta.
                            Opetus perustuu taiteen perusopetuslakiin se on valtion tukemaa. Laajan oppimäärän opinnot koostuvat perusopinnoista ja syventävistä opinnoista.
                        </p>
                        <a target="_blank" rel="noreferrer" href='https://drive.google.com/file/d/1B3sq91-cvlAiYUZHFQZT2vuYJ0Hp1LXn/view'>Lue opetussuunnitelma tästä</a>

                    </div> 
                        <div className='instrumentit'>
                            <div style={{width: "100%"}} className='opiskelu-2-text'>
                                <p id='taiteen-perus'>
                                   <strong>Taiteen perusopetuksen oppilaan viikoittainen ohjelma sisältää seuraavat tunnit:</strong>
                                </p>
                                <ul style={{ padding: "20px"}}>
                                    <li>oma 30-60 minuutin soitto- tai laulutunti</li>
                                    <li>yhteismusisointia (orkesterit, bändit, kuorot, kamarimusiikki jne.) </li>
                                    <li>10-vuotiaasta alkaen musiikin hahmotusaineita (muha)</li>
                                    <li>Kotiharjoittelua</li>
                                </ul>
                                
                             
                            
                            </div>
                                <div className='puu-kuva'>
                                    <img style={{ width: "500px"}} alt="kum musiikiopisto musiikin harrastus" src={Harrastus}></img>
                                </div>
                            
                        </div>
                        <br></br>
                        <hr></hr>
                        <br></br>
                <div className='instrumentit'>
                            <div className='puu-kuva'>
                                <img  alt="opinnot kuva kum" style={{ width: "700px"}}  src={OpinnotKuva}></img>
                            </div>
                            <div style={{ width: "100%"}} className="opiskelu-2-text">
                                <p><strong>Perusopinnot</strong></p>
                                        <p>
                                            Perusopinnot koostuvat kolmesta opintokokonaisuudesta ja niihin jokaiseen kuuluu instrumenttiopintojen lisäksi yhteissoittoa, musiikin hahmotusaineita ja kotiharjoittelua. 
                                            Tasolta toiselle siirryttäessä soitetaan tasokonsertti, jonka voi toteuttaa hyvin yksilöllisellä tavalla ja sen sisällön voi esimerkiksi jakaa useampaan esiintymiseen.
                                        </p>
                                        <br></br>
                                        <p><strong>Syventävät opinnot</strong></p>
                                        <p>
                                            Syventävien opintojen tarkoituksena on opintomahdollisuuksien laajentaminen tai painottaminen. Oppilas valitsee kursseja ja yhteismusiikkia oman kiinnostuksensa ja tavoitteidensa mukaan. 
                                            Syventäviin opintoihin sisältyy lopputyö, joka suunnitellaan yhdessä opettajan kanssa ja jonka voi toteuttaa monilla eri tavoilla. 
                                        </p>
                                        <br></br>
                                        <p>Suoritettuaan perusopinnot ja syventävät opinnot oppilas saa musiikin laajan oppimäärän päättötodistuksen.</p>

                                        <br></br>
                                        <p id="avoinopetus"><strong>Avoin opetus</strong></p>
                                        <p>
                                            Avoimeen opetukseen voi hakeutua mihin tahansa tarjolla olevaan instrumenttiin.
                                            Avoimeen musiikkiopistoon on jatkuva ilmoittautuminen ja oppilaat valitaan soitinkohtaisessa ilmoittautumisjärjestyksessä vapaille oppilaspaikoille. 
                                            Otamme yhteyttä heti, kun voimme tarjota oppilaspaikan.
                                        </p>
                                        <p>
                                            Avoimeen opetukseen ei ole ikä- tai kotikuntarajoituksia.  Opetus ei ole sidottu opetussuunnitelmaan, vaan tavoitteet määritellään yhdessä oppilaan kanssa. 
                                            Avoin opetus ei ole valtion tukemaa, jonka vuoksi opetus on taiteen perusopetusta kalliimpaa.

                                        </p>

                            </div>
                </div>
                      
                <br></br>

                
                <div id="second" className='opiskelu-1'>
                        <div className='instrumentit'>
                            <div className='puu-kuva'>
                                <img alt="kum musiikiopisto soittimet" src={Puu}></img>
                            </div>
                        
                            <div className='opiskelu-2-text'>
                                <h2>Opetettavat instrumentit</h2>
                                <br></br>
                                <p><strong>Soittimen hankinta / vuokraus</strong></p>
                                <p>
                                    Oman soitin on tärkeää hankkia tai lainata heti harrastuksen alussa, jotta oppilas pääsee harjoittelemaan myös kotona. Oma opettaja neuvoo soittimen hankinnassa opetuksen alettua.
                                </p>
                                <p>
                                    Musiikkiopistolla on jonkin verran vuokrasoittimia, joita on mahdollista vuokrata opintojen alkuvaiheessa. Vapaita soittimia voi tiedustella omalta opettajalta. Lainasoittimesta tehdään vuokrasopimus.
                                </p>
                                <br></br>
                                <p id="soitinkokeilu"><strong>Soitinkokeilu</strong></p>
                                <p>
                                    Soitinkokeiluja järjestetään kevätlukukaudella, ja niihin ilmoittautuminen alkaa tammikuussa.

                                </p>
                                <p>
                                    Mikäli olet kiinnostuntu kokeilemaan tiettyä soitinta tai laulutunteja, mutta et ole varma miten harrastus lähtee käyntiin, voit ilmoittautua soitinkokeiluun.
                                </p>
                                <br></br>
                                <p>
                                    Soitinkokeilu kestää 4 kertaa 30 minuuttia, jona aikana ehdit saamaan kuvan soittimen käsittelystä ja soittamisesta.
                                    Jos innostus syttyi soitinkokeilun jälkeen, voit hakea avoimeen yksilöopetukseen tai perusopetukseen jatkamaan soittoharrastusta! 
                                </p>
                                <p>
                                    Soitinkokeilut ovat kaikinikäisille ja -tasoisille. Opetuksen kesto 4x30 min.
                                    Opistolla on lainattavissa soittimia. Soitinvuokra on 15 € / soitinkokeilu. 
                                    Lauluun soitinkokeilun alaikäraja on 15 vuotta.
                                </p>

                            </div>
                        </div>

                </div>
               
                <br></br>
                <div id="three" className='opiskelu-1-text'>
                      
                            <h2>Ryhmäopetus</h2>
                            <p><strong>Muskari ja musiikkivalmennus</strong></p>
                            <p>Musiikkileikkikoulu (0–6-vuotiaille) ja musiikkivalmennus (7–8-vuotiaille) tarjoavat pienimmille oppilaille musiikin iloa ja yhteismusisointia. Lue lisää muskareista 
                                <a href='/muskari'> täältä.</a></p>


                            <p id="musikantit"><strong>Koulumusikantit ja Musikantit</strong></p>
                            <p>
                                Koulumusikanttiopetuksessa opiskellaan pienryhmässä (2-5 oppilaan ryhmissä) soiton alkeita oppilaan lähikoululla  iltapäivällä koulupäivän jälkeen. 
                                Opetukseen voi ilmoittautua omalle tai muulle lähikoululle. Koulumusikanttiopetusta järjestetään pianon, kitaran ja huilunsoitossa.
                                Opetus tapahtuu soittoparin kanssa 30 min kerralla tai pienryhmässä (3-6 oppilasta) 45 min kerralla 10 kertaa lukukaudessa. 

                            </p>
                            <p>
                                Musikanttien opetus tapahtuu musiikkiopiston tiloissa Järvenpäässä (Seutulantie 8) tai Tuusulassa (Kirkkotie 49). Musikanttiopetukseen ei ole ikärajaa, joten jos aikuisena etsit itsellesi soittotuntia, voit tulla vaikka lapsesi kanssa.

                            </p>
                            <p>
                                Koulumusikantti- ja musikanttiopetus ei edellytä aikaisempaa soitto-/ tai laulukokemusta. Ryhmä voidaan perustaa, kun ilmoittautuneita on vähintään kaksi. Kannattaa siis pyytää kaveria mukaan! Ryhmiä voidaan perustaa lisää tai yhdistää tarpeen mukaan. Ryhmät jatkavat syyslukukaudelta kevätlukukaudelle ilman erillistä ilmoittautumista.

                            </p>

                            <p><strong>Yhteismusiointi</strong></p>
                            <p>
                                Musiikkiopistolla on monenlaisia mahdollisuuksia yhteismusisointiin: kuoroja, orkestereita, bändejä, yhtyeitä jne. 
                                Yhteismusisointi antaa intoa harrastukseen ja tukee oppimista. Oma opettaja ja toimisto antavat neuvoja sopivan ryhmän löytämiseen.
                            </p>
                            <p><a href='https://docs.google.com/spreadsheets/d/1NiMi6blRnlpYWIbr3MYNY7kPyq3-CXRw/edit?usp=drive_link&ouid=109116586319455381401&rtpof=true&sd=true'
                                target="__blank" rel="noreferrer" 
                                >
                            
                                Katso täältä orkesterien ja kuorojen aikataulut 2025-2026.</a></p>

                            <p><strong>Muha eli musiikin hahmotus</strong></p>
                            <p>Muhaopinnoissa, eli musiikin hahmotusaineissa, opiskellaan nuotinlukua, musiikki-tietoutta ja säveltapailua. 
                                Muhaopinnot sisältyvät taiteen perusopetuksen opintoihin ja ne tukevat instrumenttiopintoja. Opinnot aloitetaan yleensä noin 10-vuotiaana. 
                                Opetusta on kerran viikossa, ja tunnin pituus vaihtelee eri kursseilla.
                            </p>
                            <p><a href="https://docs.google.com/spreadsheets/d/1K3mPPzz69q3R-QofnlXc8IxUCVb2Gi6o1WOZdrzcVF8/edit?usp=drive_link"
                                target="__blank" rel="noreferrer" 
                                >
                                Tutustu lukuvuoden 2025–2026 ryhmiin.</a>
                                
                                </p>
                </div>

                    <br></br>
                
                            <br></br>
                  
                       
                <div id="ilmoittautuminen" className='opiskelu-1-text'>
                            <h2>Ilmoittautuminen</h2>
                            <p>
                                Taiteen perusopetuksen oppilaaksi ilmoittaudutaan keväisin Eepos-oppilashallintojärjestelmässä osoitteessa <a href='https://kum.eepos.fi' rel="noreferrer"  target="_blank">kum.eepos.fi</a> Järvenpääläiset ja tuusulalaiset oppilaat valitaan ilmoittautumisjärjestyksessä instrumenttikohtaisesti. 
                                Avoimeen opetukseen voi hakeutua keväällä ja loppuvuodesta. 
                            
                            </p>
                            <p>
                                Avoimen opetuksen paikat myönnetään harkinnanvaraisesti kulloisenkin oppilastilanteen mukaan. 
                                Koulumusikanttiopetukseen ilmoittautuminen alkaa keväällä ja elokuun loppuun saakka.
                            </p>
                            

                </div>
                        <br></br>
                        <br></br>
                </div>
            
            </div>
        </div>
    )
}

export default opiskelu